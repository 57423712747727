
import { createApp, h } from 'vue'
import layout from "./app.vue";
import "./styles/app.css";

const Bottleneck = require('bottleneck');
const limiter = new Bottleneck({
	reservoir: 120,
	reservoirIncreaseAmount: 4,
	reservoirIncreaseInterval: 250,
	reservoirIncreaseMaximum: 120,

	minTime: 25,
	//maxConcurrent: 10,
});
// Listen to the "failed" event
limiter.on("failed", async (error, jobInfo) => {
	const id = jobInfo.options.id;
	//console.warn(`Job ${id} failed: ${error.message}`);

	const time = Math.max(500 * (jobInfo.retryCount * jobInfo.retryCount), 500);
	//console.log(`Retrying job ${id} in ${time}ms!`);
	return time;
});

// Listen to the "retry" event
//limiter.on("retry", (error, jobInfo) => console.log(`Now retrying ${jobInfo.options.id}`));

try {
	const app = createApp({
		render: () => h(layout)
	});
	app.config.globalProperties.$limiter = limiter;
	app.mount("#app").window = window;
} catch (err) {
	console.error(err);
}

var script = document.createElement('script');
script.src = "https://cl-planner.com/js/Produktinfo.js";
document.head.appendChild(script); //or something of the likes
