<template>
  <div class="w-full flex justify-around border-b border-gray-700 py-3 mb-3">
    <div class="w-1/3 text-sm text-gray-500 text-center">
      Add to the end of URL eg. https://cl-planner.com/planner/index.html?dom=com&user=C<span class="text-gray-300">&menu=outdoor-dining-chairs</span>
    </div>
  </div>
  <div class="w-full flex">
    <div class="w-3/6 text-right border-r-2 border-gray-600 pr-2">
			<h2>Menus:</h2>
			<ul class="float-right">
          <Menulink v-for='i in menus' :key='i' :link='i' />
			</ul>
    </div>
    <div class="w-3/6 pl-2">
			<h2>Series:</h2>
			<ul>
        <SeriesLink v-for='i in series' :key='i' :link='i' />
			</ul>
    </div>
  </div>
</template>

<script>
import { ClipboardCopyIcon } from "@heroicons/vue/solid"
import Menulink from '../Components/Menulink'
import SeriesLink from '../Components/SeriesLink'

export default {
  components: {
    ClipboardCopyIcon,
    Menulink,
    SeriesLink
  },
  data() {
    return {
      menus: [],
      series: [],
    }
  },
    created() {
        for(var i in window.productGroup) {
          var l = window.productGroup[i];
          if(l.MID === "COLLECTIONS") {
            this.series.push(l.NM_EN);
          } else if(l.hasOwnProperty('tag')) {
            this.menus.push({
              name: l.NM_EN,
              tag: l.tag,
              env: (l.MID.indexOf('OUTDOOR') !== -1 ? 'outdoor' : (l.MID.indexOf('INDOOR') !== -1 ? 'indoor' : '')),
            });
          }
        }
    }

}
</script>

<style>

</style>
