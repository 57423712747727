const axios = require('axios').default;
const Bottleneck = require('bottleneck');
const ignored = require('../../skutoignore.json');

var checkerModule = (function (data, limiter) {
	var productData = data.productData;
	var productGroup = data.productGroup;
	var collectionNames = [];
	var shortSKUs = {};
	var shopify_url = "";
	var shopify_token = "";
	var shopifyDom = "";
	var shopifyData = {};
	var artikelPriceFound = {};
	var allVariants = [];
	var missingVariants = [];

	var init = function () {
		collectionNames.push("Accessories"); // additional collection name for parasol base
		for (var g = 0; g < productGroup.length; g++) {
			var currGroup = productGroup[g];
			// collection names
			if (collectionNames.indexOf(currGroup.NM_EN) == -1) collectionNames.push(currGroup.NM_EN);
		}
	};

	var sleep = function (ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	var collectionPromises = function () {
		let promises = [];
		for (var i in collectionNames) {
			var cName = collectionNames[i];
			promises.push(LoadJSONforCollection(cName));
		}
		return promises;
	};

	const filterIgnore = function (item, sku) {
		var index = false;
		if (item.indexOf('*') > 0) index = 0;
		if (item.indexOf('*') == 0) index = 1;
		if (index !== false) item = item.replace('*', '');
		return typeof item == 'string' && ((index && sku.indexOf(item) > index) || (!index && sku.indexOf(item) == 0));
	}

	const shouldIgnore = function (sku) {
		for (var pattern in ignored) {
			// Check if the 'shops' property exists and if 'shopifyDom' is not included
			if (
				ignored[pattern].hasOwnProperty("shops") &&
				ignored[pattern].shops.indexOf(shopifyDom) === -1
			)
				continue;

			// Use the 'patternMatches' function to check if the SKU matches the pattern
			if (typeof pattern == 'string' && patternMatches(sku, pattern)) {
				return true;
			}
		}
		return false;
	};

	const patternMatches = function (sku, pattern) {
		// Escape special regex characters except '*'
		var escapedPattern = pattern.replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&');
		// Replace '*' with '.*' to match any number of any characters
		var regexPattern = '^' + escapedPattern.replace(/\*/g, '.*') + '$';
		// Create a new RegExp object
		var regex = new RegExp(regexPattern);
		// Test if the SKU matches the regex pattern
		return regex.test(sku);
	};

	var LoadJSONforCollection = async function (collectionName) {
		var that = this;
		var query = '{ products(first:250, query: "tag:' + collectionName + '") { edges { node { id title variants(first:100) { edges { node { title id sku priceV2 { amount currencyCode } } } } } } } }';
		const nameSlug = collectionName
			.toLowerCase()
			.trim()
			.replace(/[^\w\s-]/g, '')
			.replace(/[\s_-]+/g, '-')
			.replace(/^-+|-+$/g, '');
		return await throttledShopifyRequest.withOptions({ id: shopifyDom + '.' + nameSlug }, query).then((response) => {
			var rData = response.data;

			if (rData.data) {
				if (rData.data.products) {
					var rProducts = rData.data.products.edges;
					for (var p = 0; p < rProducts.length; p++) {
						var rTitle = rProducts[p].node.title;
						var rVariants = rProducts[p].node.variants.edges;
						for (var v = 0; v < rVariants.length; v++) {
							var rVariant = rVariants[v].node;
							var skuFull = rVariant.sku;
							var sku = skuShorten(skuFull);

							//console.log(skuFull);

							var debugThisOne = false;
							//if (skuFull.indexOf("583X3Y") >= 0) debugThisOne = true;

							if (debugThisOne) console.log(rVariants);
							if (debugThisOne) console.log("skuFull " + skuFull);
							if (debugThisOne) console.log("skuShort " + sku);

							if (shortSKUs[sku] !== undefined) {
								var localSKU = shortSKUs[sku]; // OUR stored short sku, differs from full rVariant.sku!

								if (debugThisOne) console.log("localSKU " + localSKU);
								if (debugThisOne) console.log("collection " + collectionName);
								if (debugThisOne) console.log("shopifySKU " + shopifyData[localSKU].sku);

								if (shopifyData[localSKU].sku == sku) {
									if (debugThisOne) console.log("shopifyCollection " + shopifyData[localSKU].collection);

									if (collectionName == shopifyData[localSKU].collection || collectionName == 'News 2022' || collectionName == "Hyde Luxe") {

										if (debugThisOne) console.log("collectionName " + collectionName);

										currency = rVariant.priceV2.currencyCode;
										shopifyData[localSKU].price = parseFloat(rVariant.priceV2.amount);
										shopifyData[localSKU].id = rVariant.id;
										shopifyData[localSKU].title = rTitle;
										shopifyData[localSKU].subtitle = rVariant.title;

										artikelPriceFound[localSKU] = true;
										//console.log("Shopify Price for " + skuFull + ": " + shopifyData[localSKU].price);

										//console.log("WRONG COLLECTION TAG: " + skuFull + " " + shopifyData[localSKU].name + " is: " + collectionName + " should be " + shopifyData[localSKU].collection);
									} else {
										// special case "Peacock Parasol": Collection "Classics" instead of "Peacock"
										if (["58240TY506", "58240TY507"].indexOf(localSKU) != -1) artikelPriceFound[localSKU] = true;

									}
								}
							}
						}
					}
				}
			}
		}).catch((error) => {
			console.error(error);
		});
	};

	var throttledShopifyRequest = limiter.wrap(sendShopifyQuery);

	async function sendShopifyQuery(queryString, tries = 0) {
		const response = await axios.post(shopify_url, queryString, {
			headers: {
				"Content-type": "application/graphql",
				"Accept": "application/json",
				"X-Shopify-Storefront-Access-Token": shopify_token,
			}
		}).catch((error) => {
			console.error(error);
			throw new Error(error.message);
		});
		if (response.data.errors) {
			throw new Error(response.data.errors.message);
			/*if (tries > 5) {
				console.error(response.data.errors);
				console.error(response.headers, response.status);
				return;
			}*/
			tries++;
			const time = 500 * (tries * tries);
			console.log('sleep ' + time);
			await sleep(time);
			return sendShopifyQuery(queryString, tries);
		}
		return response;
	};

	var generateSKU = function (currProduct, option1, option2, option3) {

		//if (currProduct.ID == "5456") console.log("generateSKU " + currProduct.ID + " " + option1 + " " + option2 + " " + option3);

		var sku = currProduct.ID;
		if (currProduct.SKU1 !== undefined) sku = currProduct.SKU1;

		var sku1 = currProduct.O1[option1];
		if (sku1 == "WT") sku1 = "T";
		sku += sku1;

		if ((option2 >= 0) && (currProduct.O2 !== undefined)) {
			if (currProduct.O2[option2] != "NONE") {
				var sku2 = currProduct.ID;
				var addSku2 = true;
				if (currProduct.SKU2 !== undefined) {
					if (currProduct.SKU2[option2] == "") {
						addSku2 = false;
					} else {
						sku2 = currProduct.SKU2[option2];
					}
				}
				if (addSku2) sku += "-" + sku2 + currProduct.O2[option2];

				if (option3 >= 0) {
					var sku3 = sku2;
					if (currProduct.SKU3 !== undefined) sku3 = currProduct.SKU3[option3];
					sku += "-" + sku3 + currProduct.O2[option3];
				}
			}
		} else if (currProduct.SKU2 !== undefined) {
			// Plattenname ohne separate Auswahl
			sku += "-" + currProduct.SKU2[option1];
		}


		return sku;
	};

	var skuShorten = function (fullSKU) {
		var skuParts = fullSKU.split('-');
		var lastPart = skuParts[skuParts.length - 1];

		// Check if the last part is a number between 100 and 99999
		if (/^\d+$/.test(lastPart)) {
			var num = parseInt(lastPart, 10);
			if (num >= 100 && num <= 99999) {
				// Remove the last part
				skuParts.pop();
			}
		}

		return skuParts.join('-');
	};


	function getPaginatedElements(getResourcePromise, progressFn, limit = 25, offset = 0, elements = []) {
		return new Promise((resolve, reject) =>
			getResourcePromise({ limit, offset })
				.then(response => {
					const newElements = elements.concat(response.records);
					if (response.records.length === 0) {
						resolve(newElements);
					} else {
						progressFn && progressFn(elements);
						getPaginatedElements(getResourcePromise, progressFn, limit, offset + limit, newElements)
							.then(resolve)
							.catch(reject)
					}
				}).catch(reject));
	}

	var loadMissingVariants = async function (after = false, resolve, reject) {
		var query = '{ products(query: "tag_not:Cushion",first:250) { edges { cursor node { id title variants(first:75) { edges { node { title id sku priceV2 { amount currencyCode } } } } } } pageInfo { hasNextPage } } }';
		if (after) {
			query = '{ products(query: "tag_not:Cushion",first:250, after:"' + after + '") { edges { cursor node { id title variants(first:75) { edges { node { title id sku priceV2 { amount currencyCode } } } } } } pageInfo { hasNextPage } } }';
		}

		await throttledShopifyRequest(query).then((r) => {
			var data = r.data.data;
			if (r.data.errors) {
				console.error(r.data.errors);
				console.error(r.headers, r.status);
			}
			var products = data.products.edges;
			for (x in products) {
				var p = products[x];
				after = p.cursor;
				//console.log(p.node);
				var variants = p.node.variants.edges;
				for (i in variants) {
					var v = variants[i].node;
					var sku = v.sku.replace(/-\d+$/, '');
					if (shouldIgnore(sku)) {
						//console.log('ignored on website: ' + sku);
						continue;
					}
					if (!shortSKUs[sku]) {
						missingVariants.push({
							sku: sku,
							name: p.node.title + ' ' + v.title
						});
					}
					//allVariants.push(v.sku.replace(/-\d+$/, ''));
				}
			}
			pageInfo = data.products.pageInfo;
			if (pageInfo.hasNextPage) {
				loadMissingVariants(after, resolve, reject)
			} else {
				resolve();
				return;
			}
		}).catch(error => {
			console.error(error);
			//reject(error);
		});
	};

	var testTag = function (url, token, tag) {
		shopify_url = url;
		shopify_token = token;
		var query = '{ products(first:150, query: "tag:' + tag + '") { edges { node { id title variants(first:150) { edges { node { title id sku priceV2 { amount currencyCode } } } } } } } }';
		return new Promise((resolve, reject) => {
			throttledShopifyRequest(query).then((r) => {
				if (r.status === 200) resolve(r);
				reject(r);
			});
		});
	};

	var start = function (url = null, token = null) {
		return new Promise((resolve, reject) => {
			shopify_url = url;
			shopify_token = token;
			shopifyDom = shopify_url.split(".")[0].split("-").pop();
			if (shopifyDom == "https://canelinedemo") shopifyDom = "com";
			init();

			shopifyData = {};
			artikelPriceFound = {};
			anzahlpreise = 0;

			for (var p = 0; p < productData.length; p++) {
				var currProdukt = productData[p];
				var currID = currProdukt.ID;
				if (currProdukt.A == "K") continue;

				if (currProdukt.EUR === undefined) {
					// auto-generate SKUs
					var autoSKU = [];

					for (var option1 = 0; option1 < currProdukt.O1.length; option1++) {

						var option2 = 0;
						var tSKU = "";

						if (currProdukt.O2 !== undefined) {
							for (var option2 = 0; option2 < currProdukt.O2.length; option2++) {
								tSKU = generateSKU(currProdukt, option1, option2, -1);
								autoSKU.push(tSKU);
							}

							if (currProdukt.B3 !== undefined) {
								// option3 aktiv dazu, wieder loop über option2
								for (var option3 = 0; option3 < currProdukt.O2.length; option3++) {
									tSKU = generateSKU(currProdukt, option1, option3, option3); // option2 == option3 !
									autoSKU.push(tSKU);
								}
							}
						} else {
							tSKU = generateSKU(currProdukt, option1, -1, -1);
							autoSKU.push(tSKU);
						}
					}
					currProdukt.EUR = autoSKU;
				}

				for (var eur = 0; eur < currProdukt.EUR.length; eur++) {
					var sku = currProdukt.EUR[eur];
					if (shouldIgnore(sku)) {
						//console.log('ignored on planner: ' + sku);
						continue;
					}
					shortSKUs[sku] = sku;

					if (shopifyData[sku] === undefined) {

						var sdata = {};
						sdata.sku = sku;
						sdata.parent = currID;
						sdata.id = "";
						sdata.price = 0;
						sdata.title = "";
						sdata.subtitle = "";
						sdata.collection = currProdukt.S;
						//if(currProdukt.S == "Hyde Luxe Tilt") sdata.collection = "Hyde Luxe";
						sdata.name = currProdukt.NM_EN;

						shopifyData[sku] = sdata;
						artikelPriceFound[sku] = false; // für collection einlesen

						anzahlpreise++;
					}
				}
			}

			// alle Kollektionen durchgehen, querys erstellen
			//updateOutputDiv();
			const allPromises = collectionPromises();
			//console.log(allPromises);

			Promise.all(allPromises).then((values) => {
				//console.log(values);
				//console.log(artikelPriceFound);
				new Promise((resolve, reject) => {
					loadMissingVariants(0, resolve, reject);
				}).then(() => {
					resolve({
						pricesFound: artikelPriceFound,
						variantsMissing: missingVariants,
						shopifyData: shopifyData,
					})
				}).catch((error) => {
					console.error(error);
					reject(error);
				});
			}).catch((error) => {
				console.error(error);
				reject(error);
			});
		});
	};

	var loadJSONError = function (event) {
		console.log(event);
	} // loadJSONError

	return {
		start: start,
		testTag: testTag,
	};
});

// Productinfo.js
// ========
module.exports = checkerModule;
