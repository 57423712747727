<template>
  <div class="w-full flex justify-center items-center h-24">
    <h1>Planner tools</h1>
  </div>
  <div
    class="text-lg font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
  >
    <ul class="flex flex-wrap -mb-px justify-center">
      <li @click="selectTab(0)" class="mr-2">
        <a
          href="#"
          :class="{
            'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300':
              0 !== selectedIndex,
            'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500':
              0 === selectedIndex,
          }"
          class="rounded-t-lg border-b-2 p-4 flex gap-1"
          >Pricechecker</a
        >
      </li>
      <li @click="selectTab(1)" class="mr-2">
        <a
          href="#"
          :class="{
            'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300':
              1 !== selectedIndex,
            'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500':
              1 === selectedIndex,
          }"
          class="rounded-t-lg border-b-2 p-4 flex gap-1"
          >Menu links</a
        >
      </li>
      <li @click="selectTab(2)" class="mr-2">
        <a
          href="#"
          :class="{
            'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300':
              2 !== selectedIndex,
            'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500':
              2 === selectedIndex,
          }"
          class="rounded-t-lg border-b-2 p-4 flex gap-1"
          >Check tags on Shopify</a
        >
      </li>
    </ul>
  </div>
  <div class="">
    <Shoptester v-if="0 === selectedIndex" :shops="this.shops" />
    <Menulinks v-if="1 === selectedIndex" />
    <Tagtester v-if="2 === selectedIndex" :shops="this.shops" />
  </div>
</template>

<script>
import { createApp, h } from "vue";
import Menulinks from "./Pages/Menulinks";
import Tagtester from "./Pages/Tagtester";
import Shoptester from "./Pages/Shoptester";

export default {
  components: { createApp, h, Menulinks, Tagtester, Shoptester },
  data() {
    return {
      shops: {},
      chosenShop: false,
      running: false,
      checker: null,
      checkers: [],
      selectedIndex: 0,
    };
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
    },
    getData() {
      let shops = {};
      for (var i in domData) {
        shops[i] = {
          token: domData[i].token,
          api: domData[i].shopifyURL,
        };
      }
      this.shops = Object.keys(shops)
        .sort()
        .reduce((r, k) => ((r[k] = shops[k]), r), {});
    },
  },
  mounted() {
    this.selectTab(0);
    window.onload = () => {
      this.getData();
    };
  },
};
</script>
