<template v-if="link">
		<li class="flex justify-between w-96 hover:text-gray-300 hover:cursor-pointer" @click="copy">
          <div class="">
            <ClipboardCopyIcon class="h-5 inline pr-2" />
            <span v-if='link.env == "outdoor"' class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline text-white rounded-full border-2 border-green-800">Outdoor</span>
            <span v-if='link.env == "indoor"' class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline border-2 border-cyan-800 text-white rounded-full">Indoor</span>
            <span class="tool-tip ml-2 text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline border-2 border-blue-500 text-white rounded-full bg-blue-500" :class="{ active: showTooltip }">Kopieret</span>
          </div>
          <div>
            <span class="inline-block leading-none align-middle">
                {{ link.name }}
            </span>
          </div>
            <!--<div class="relative flex flex-col items-center opacity-0 ml-auto mr-0" :class="{ active: showTooltip }">
                <span class="relative p-2 text-xs leading-none text-white whitespace-no-wrap rounded-md bg-gray-700 shadow-lg">Kopieret</span >
            </div>-->
        </li>
</template>
<script>
  import { ClipboardCopyIcon } from '@heroicons/vue/outline'
  export default {
      components: {
          ClipboardCopyIcon,
      },
      props: {
          link: null,
      },
      data() {
          return {
              showTooltip: false
          }
      },

      methods: {
          copy() {
              this.showTooltip = true
              navigator.clipboard.writeText("&menu="+this.link.tag)
              setTimeout(() => {
                  this.showTooltip = false
              }, 600)
          },
      },
  }
</script>
<style scoped>
.tool-tip.active {
    @apply opacity-100;
}

.tool-tip {
    @apply opacity-0;
    transition: opacity 0.4s;
}
</style>
