<template lang="html">
  <div class='text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700'>
    <ul class='flex flex-wrap -mb-px justify-center'>
      <li v-for='(tab, index) in tabs'
        :key='tab.slug'
        @click='selectTab(index)'
        class="">
          <a href="#" :class="{
            'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': (index !== selectedIndex),
            'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500': (index === selectedIndex)
          }" class="rounded-t-lg border-b-2 py-4 px-2 flex gap-1">
            <ButtonSpinner v-show='!finished.includes(tab.slug) && !errored.includes(tab.slug) && !warning.includes(tab.slug)' />
            <XIcon v-show='errored.includes(tab.slug)' class="h-6 text-red-800" />
            <CheckIcon v-show='finished.includes(tab.slug)' class="h-6 text-green-600" />
            <ExclamationIcon v-show='warning.includes(tab.slug)' class="h-6 text-orange-600" />
            {{ tab.name }}
          </a>
      </li>
    </ul>
  </div>
  <div class="pt-8">
    <Tab v-for='(tab, index) in tabs' :key='tab.slug' v-show='index === selectedIndex'>
      <Checker v-if='!tab.tag' :shop="tab" @onFinish='markFinished' @onError="markFailed" @onWarn="markWarning" />
      <Tagchecker v-if='tab.tag' :shop="tab" :tag="tag" @onFinish='markFinished' @onError="markFailed" @onWarn="markWarning" />
    </Tab>
    <slot></slot>
  </div>
</template>

<script>
import Tab from './Tab'
import Checker from './Checker'
import Tagchecker from './Tagchecker'
import ButtonSpinner from './Icons/ButtonSpinner'
import { XIcon, CheckIcon, ExclamationIcon } from "@heroicons/vue/solid"

export default {
  components: {
    Tab, Checker, Tagchecker, ButtonSpinner, XIcon, CheckIcon, ExclamationIcon
  },
  props: {
      tabs: Array,
      tag: false,
  },
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      finished: [],
      errored: [],
      warning: [],
    }
  },
  mounted () {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      if(!this.tabs) return;
      this.selectedIndex = i
    },
    markFinished(tab) {
      // Find tab in tabs and change name;
      var found = this.tabs.find((t, i) => {
        if(t.slug === tab.slug) return true;
      });
      if(found) found.name = tab.name;
      this.finished.push(tab.slug);
    },
    markFailed (tab) {
      this.errored.push(tab);
    },
    markWarning (tab) {
      this.warning.push(tab);
    },
    getStatus (tab) {
      if(this.errored.includes(tab)) return 'error';
      if(this.doneRunning.hasOwnProperty(tab)) {
        return this.doneRunning[tab].warn ? 'warn' : 'done';
      }
      return 'loading';
    }
  }
}
</script>
