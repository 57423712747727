<template>
  <div class="w-full flex justify-around border-b border-gray-700 py-3 mb-3">
    <div class="w-1/3 text-sm text-gray-500 text-center">
      Test data from Shopify
    </div>
  </div>
  <div class="w-full flex justify-center gap-2 py-4 pb-6">
    <div class="relative">
      <input id="tag-input" type="text" class="input input-bordered" v-model='tag' />
      <label class="absolute -bottom-5 left-2 mr-2 text-xs text-gray-500" for="tag-input">Tag</label>
    </div>
    <div class="relative max-w-xs">
      <select id="shops-select" class="select select-bordered w-full"
        v-model="chosenShop">
        <option
          v-for="(shop, key) in this.shops"
          :key="key"
          :value="key"
          :selected="Object.keys(this.shops)[0] == key"
        >
          {{ key }}
        </option>
      </select>
      <label class="absolute -bottom-5 left-2 mr-2 text-xs text-gray-500" for="shops-select">Shop</label>
    </div>
    <button class="inline-flex items-center gap-2 h-12 cursor-pointer rounded-md bg-gray-800 px-4 py-3 text-center text-sm font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-gray-900" @click="startCheck">
      <span>Check</span>
    </button>
    <button class="inline-flex items-center gap-2 h-12 cursor-pointer rounded-md bg-gray-800 px-4 py-3 text-center text-sm font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-gray-900" @click="startAll">
      <span>Check All</span>
    </button>
  </div>
  <div class="">
		<Tabs :tabs="this.checkers" />
  </div>
</template>

<script>
import { ClipboardCopyIcon } from "@heroicons/vue/solid"
import Menulink from '../Components/Menulink'
import SeriesLink from '../Components/SeriesLink'
import Tabs from '../Components/Tabs'

export default {
  components: {
    ClipboardCopyIcon,
    Menulink,
    SeriesLink,
    Tabs
  },
  props: {
    shops: {},
  },
  data() {
    return {
      chosenShop: false,
      checkers: [],
      tag: "",
    }
  },
  created() {
  },
  methods: {
    startCheck() {
      if(!this.chosenShop) {
        this.chosenShop = Object.keys(this.shops)[0];
      }
      this.start(this.chosenShop);
    },
    start(shop) {
      if(this.tag === "") return;
      var count = 0;
      for(var i in this.checkers) {
        if(this.checkers[i].name.indexOf(shop) == 0) count++;
      }
      var name = shop;
      if(count > 0) {
        name = shop+" "+count++;
      }
      this.checkers.push({
        slug: this.slugify(this.tag+"_"+name),
        name: name,
        shop: shop,
        token: this.shops[shop].token,
        api: this.shops[shop].api,
        tag: this.tag,
      });
    },
    startAll() {
      for(var i in this.shops) {
        this.start(i);
      }
    },
    slugify(text) {
      return text
        .toString()                           // Cast to string (optional)
        .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                                  // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-')            // Replace spaces with -
        .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }
  }

}
</script>

<style>

</style>
