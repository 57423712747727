<template>
  <div class="flex justify-around">
    <div class="w-6/12">
      <h2>Fundne varianter: {{ skus.length > 0 ? skus.length : "" }}</h2>
      <ul>
        <li class="border-b border-gray-600">
          <div class="w-3/12 inline-block">Sku</div>
          <div class="w-8/12 inline-block">Name</div>
          <div class="w-1/12 text-right inline-block">Price</div>
        </li>
        <li v-for="i in skus" :key="i.sku" class="hover:bg-slate-700">
          <div class="w-3/12 inline-block">{{ i.sku }}</div>
          <div class="w-8/12 inline-block">
            {{ i.product }}
            <span class="text-xs block text-gray-500">{{ i.variant }}</span>
          </div>
          <div class="w-1/12 text-right inline-block">{{ i.price }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ShopifyChecker from "../modules/ShopifyChecker";

export default {
  emits: ["onFinish", "onError", "onWarn"],
  components: { ShopifyChecker },
  props: {
    shop: null,
  },
  data() {
    return {
      checker: null,
      skus: [],
    };
  },
  created() {
    this.checker = new ShopifyChecker(
      {
        productData: null,
        productGroup: null,
      },
      this.$limiter
    );
    var check = this.checker.testTag(
      this.shop.api,
      this.shop.token,
      this.shop.tag
    );
    check
      .then((results) => {
        this.prepareResults(results.data.data);
        this.$emit("onFinish", {
          name: this.shop.name + " (" + this.skus.length + ")",
          slug: this.shop.slug,
        });
      })
      .catch((error) => {
        this.$emit("onError", this.shop.slug);
        //console.error(error);
      });
  },
  methods: {
    prepareResults(shopData) {
      var all = [];
      for (var i in shopData.products.edges) {
        var p = shopData.products.edges[i].node;
        for (var x in p.variants.edges) {
          var v = p.variants.edges[x].node;
          all.push({
            sku: v.sku,
            product: p.title,
            variant: v.title,
            price: v.priceV2.amount + " " + v.priceV2.currencyCode,
          });
        }
      }
      all.sort((a, b) => {
        return a.sku > b.sku ? 1 : -1;
      });
      this.skus = all;
    },
  },
};
</script>

<style></style>
