<template>
  <div style="display: flex">
    <div class="border-r-2 border-gray-500 w-6/12 pl-2">
      <h2>
        Missing prices:
        {{ missingPrices.length > 0 ? missingPrices.length : "" }}
      </h2>
      <ul>
        <li v-for="i in missingPrices" :key="i.sku">
          {{ i.sku }} {{ i.name }}
        </li>
      </ul>
    </div>
    <div class="w-6/12 pl-2">
      <h2>
        Missing on planner:
        {{ missingPlanner.length > 0 ? missingPlanner.length : "" }}
      </h2>
      <ul>
        <li v-for="i in missingPlanner" :key="i.sku">
          {{ i.sku }} {{ i.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ShopifyChecker from "../modules/ShopifyChecker";

export default {
  emits: ["onFinish", "onError", "onWarn"],
  components: { ShopifyChecker },
  props: {
    shop: null,
  },
  data() {
    return {
      checker: null,
      missingPrices: [],
      missingPlanner: [],
    };
  },
  created() {
    this.checker = new ShopifyChecker(
      {
        productData: window.productData,
        productGroup: window.productGroup,
      },
      this.$limiter
    );
    var check = this.checker.start(this.shop.api, this.shop.token);
    check
      .then((results) => {
        this.prepareResults(results);
        if (
          this.missingPrices.length > 0 /* || this.variantsMissing.length > 0*/
        ) {
          this.$emit("onWarn", this.shop.slug);
        } else {
          this.$emit("onFinish", {
            name: this.shop.name,
            slug: this.shop.slug,
          });
        }
      })
      .catch((error) => {
        this.$emit("onError", this.shop.slug);
        //console.error(error);
      });
  },
  methods: {
    prepareResults(shopData) {
      for (var i in shopData.pricesFound) {
        if (!shopData.pricesFound[i]) {
          var sData = shopData.shopifyData[i];
          this.missingPrices.push({
            sku: i,
            name: sData.collection + " " + sData.name,
          });
        }
      }
      this.missingPlanner = shopData.variantsMissing;
    },
  },
};
</script>

<style></style>
